import ICAL from "ical.js";

type Termin = {
  summary: string;
  description: string | null;
  location: string | null;
  startDate: Date;
  endDate: Date | null;
  link: string | null;
};

// convert ICAL event to local event format Termin
// tries to populate link property from event
// special handling for kolloquium.cs.tu-dortmund.de to hide duplicate information
function eventToTermin(event: ICAL.Event): Termin {
  // extract link from event
  // use url property if available
  let link = event.component.getFirstPropertyValue("url");
  // if url property is not available, try to extract link from description
  if (link === null) {
    const description = event.description;
    if (typeof description === "string") {
      const regex = /https?:\/\/[^\s]+/g;
      const match = regex.exec(description.toString());
      if (match !== null) {
        link = match[0];
        event.description = description.replace(link, "");
      }
    }
  }

  // remove summary from description, if the description contains the summary
  if (event.description && event.summary) {
    const description = event.description.toString();
    const summary = event.summary.toString();
    if (description.includes(summary)) {
      // some special behavior for http://kolloquium.cs.tu-dortmund.de/
      // structure:
      // summary: "<Title> (<Type>)"
      // description: "<Person>: <Title> (<Type>)"
      // new description: "<Person>: <Type>"
      const regex =
        /([A-Za-zÄÖÜäöüß ]+): ([A-Za-zÄÖÜäöüß:,„“"\- ]+) \(([A-Za-zÄÖÜäöüß\- ]+)\)/;
      const match = regex.exec(description);
      if (match !== null) {
        event.description = match[1] + ": " + match[3];
        event.summary = summary.replace("(" + match[3] + ")", "");
      } else {
        event.description = description.replace(summary, "");
      }
    }
  }
  return {
    summary: event.summary,
    description: event.description,
    location: event.location,
    startDate: event.startDate.toJSDate(),
    endDate: event.endDate.toJSDate(),
    link: link ? link.toString() : null,
  };
}

// parse iCal content and return list of events in local format Termin
// resolves recurring events, filters out all events that end before filterStartDate (now) or that start after filterEndDate (now + future_days)
// adds calendar name to every event description
// adds default link to every event that has no link
function parseICal(
  content: string,
  cal_name: string,
  default_link: string,
  future_days: number,
): Termin[] {
  const filterStartDate = new Date();
  const filterEndDate = new Date(
    filterStartDate.getTime() + future_days * 24 * 60 * 60 * 1000,
  );

  const jCalData = ICAL.parse(content);
  const comp = new ICAL.Component(jCalData);

  const vevents = comp.getAllSubcomponents("vevent");
  const raw_events = vevents.map((vevent) => {
    return new ICAL.Event(vevent);
  });

  // expand recurring events
  var termine = [];
  for (const event of raw_events) {
    // check whether to skip event
    if (event.startDate.toJSDate() > filterEndDate) continue;

    if (event.isRecurring()) {
      const iter = event.iterator();
      let next;
      while ((next = iter.next()) && next.toJSDate() < filterEndDate) {
        // if event end date is after filterStartDate, add it
        if (
          next.toUnixTime() + event.duration.toSeconds() >
          Math.floor(filterStartDate.getTime() / 1000)
        ) {
          let newEvent = eventToTermin(event);
          newEvent.startDate = next.toJSDate();
          newEvent.endDate = next.toJSDate();
          newEvent.endDate.setSeconds(
            newEvent.endDate.getSeconds() + event.duration.toSeconds(),
          );
          termine.push(newEvent);
        }
      }
    } else {
      // if event end date is after filterStartDate, add it
      if (event.endDate.toJSDate() > filterStartDate)
        termine.push(eventToTermin(event));
    }
  }

  // add calendar name + default link to each event
  termine.forEach((termin) => {
    termin.description =
      cal_name + (termin.description ? " | " + termin.description : "");
    if (termin.link === null) termin.link = default_link;
  });

  return termine;
}

export type { Termin };
export default parseICal;
