import { useEffect, useRef, useState } from "react";
import PanelWrapper from "../../meta/PanelWrapper";
import PanelContent from "../../meta/PanelContent";
import PanelTitle from "../../meta/PanelTitle";
import QRCode from "react-qr-code";
import { Clock, MapPin } from "@phosphor-icons/react";
import { Termin } from "../../util/icalParser";
import parseICal from "../../util/icalParser";
import styles from "./TerminePanel.module.css";

export type TerminePanelDefinition = {
  calendars: [
    {
      calendar_name: string;
      url: string;
      webcal_url: string;
    },
  ];
  days: number;
};

type TerminRefined = {
  summary: string;
  description: string | null;
  location: string | null;
  startDate: string;
  endDate: string | null;
  link: string | null;
  termineCount: number;
  termineIndex: number;
};

const DEFAULT_TERMIN: Termin = {
  summary: "--- Keine Termine ---",
  description: "Fehler beim Laden oder keine Termine vorhanden.",
  location: null,
  startDate: new Date(0),
  endDate: null,
  link: null,
};

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: "short", // abbreviated day of the week
  day: "2-digit", // two-digit day
  month: "2-digit", // two-digit month
  year: "2-digit", // two-digit year
  hour: "2-digit", // two-digit hour
  minute: "2-digit", // two-digit minute
  hour12: false, // use 24-hour clock
};

function getTerminRefined(termine: Termin[], current: number): TerminRefined {
  const termin = termine[current % termine.length];

  const startDateRefined = new Intl.DateTimeFormat(
    "de-DE",
    DATE_OPTIONS,
  ).format(termin.startDate);

  const endDateRefined = termin.endDate
    ? termin.endDate.getDate() === termin.startDate.getDate()
      ? new Intl.DateTimeFormat("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }).format(termin.endDate)
      : new Intl.DateTimeFormat("de-DE", DATE_OPTIONS).format(termin.endDate)
    : null;

  const terminRefined: TerminRefined = {
    summary: termin.summary,
    description: termin.description,
    location: termin.location,
    startDate: startDateRefined,
    endDate: endDateRefined,
    link: termin.link,
    termineCount: termine.length,
    termineIndex: current % termine.length,
  };
  return terminRefined;
}

const TerminePanel = (props: { definition: TerminePanelDefinition }) => {
  const [termine, setTermine] = useState<Termin[]>([DEFAULT_TERMIN]);
  const [currentTermin, setCurrentTermin] = useState<TerminRefined>(() => {
    return {
      summary: "",
      description: null,
      location: null,
      startDate: "",
      endDate: null,
      link: null,
      termineCount: 0,
      termineIndex: 0,
    };
  });

  const cycle = useRef<number>(0);
  const summaryRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const update = async () => {
      let termine: Termin[] = [];
      // fetch calendars asynchronously
      const calendars = await Promise.all(
        props.definition.calendars.map(async (calendar) => {
          try {
            const response = await fetch(calendar.webcal_url);
            if (!response.ok) {
              throw new Error(`Fetch error code ${response.status}`);
            }
            const data = await response.text();
            return { calendar, data };
          } catch (error) {
            console.error(
              `Error fetching calendar ${calendar.calendar_name}:`,
              error,
            );
            const data = "";
            return { calendar, data }; // empty string as data if error
          }
        }),
      );
      // parse iCal data
      for (const { calendar, data } of calendars) {
        if (data === "") {
          continue;
        }
        try {
          const events = parseICal(
            data,
            calendar.calendar_name,
            calendar.url,
            props.definition.days,
          );
          termine = termine.concat(events);
        } catch (error) {
          console.error(
            `Error parsing calendar ${calendar.calendar_name}:`,
            error,
          );
        }
      }

      if (termine.length === 0) {
        termine.push(DEFAULT_TERMIN);
      }

      // sort termine by start date
      termine.sort((a, b) => {
        return a.startDate.getTime() - b.startDate.getTime();
      });
      setTermine(termine);
    };

    update();
    const interval = setInterval(update, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    const updateCurrentTermin = () => {
      const currentRefined: TerminRefined = getTerminRefined(
        termine,
        cycle.current,
      );

      cycle.current++;
      setCurrentTermin(currentRefined);
    };

    updateCurrentTermin();
    const interval = setInterval(updateCurrentTermin, 20 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [termine]);

  useEffect(() => {
    const updateMarquee = () => {
      if (summaryRef.current && containerRef.current) {
        const textWidth = summaryRef.current.scrollWidth;
        const containerWidth = containerRef.current.clientWidth;
        const translateDistance = containerWidth - textWidth;
        summaryRef.current.style.setProperty(
          "--marquee-translate",
          `${translateDistance}px`,
        );

        if (translateDistance < 0) {
          summaryRef.current.classList.add(styles.marquee);
        } else {
          summaryRef.current.classList.remove(styles.marquee);
        }
      }
    };

    updateMarquee();
    window.addEventListener("resize", updateMarquee);

    return () => {
      window.removeEventListener("resize", updateMarquee);
    };
  }, [currentTermin]);

  return (
    <PanelWrapper>
      <PanelTitle
        title={
          "Termine " +
          (currentTermin.termineCount > 1
            ? "(" +
              (currentTermin.termineIndex + 1) +
              "/" +
              currentTermin.termineCount +
              ")"
            : "")
        }
      />
      <PanelContent>
        <div className={"flex flex-row"}>
          <div className={"flex-1 w-8/12"}>
            <h2
              className={
                "text-xl font-semiboldrelative w-full overflow-hidden whitespace-nowrap "
              }
              ref={containerRef}
            >
              <span className="inline-block" ref={summaryRef}>
                {currentTermin.summary}
              </span>
            </h2>
            <p className={"text-sm text-gray-400"}>
              {currentTermin.description}
              <br></br>
            </p>
            <div className={"flex flex-row gap-4"}>
              <p className={"text-sm text-gray-400"}>
                <Clock size={20} className={"inline mb-1.5 mr-1"} />
                {currentTermin.endDate
                  ? currentTermin.startDate + " - " + currentTermin.endDate
                  : currentTermin.startDate}
              </p>
              <p className={"text-sm text-gray-400"}>
                {currentTermin.location && (
                  <>
                    <MapPin size={20} className={"inline mb-1.5 mr-1"} />
                    {currentTermin.location}
                  </>
                )}
              </p>
            </div>
          </div>
          <div>
            {currentTermin.link && (
              <QRCode
                value={currentTermin.link ?? "https://fachschaften.org"}
                className={"h-28 w-28 ml-2"}
                fgColor={"#ffffff"}
                bgColor={"#18181b"}
              />
            )}
          </div>
        </div>
      </PanelContent>
    </PanelWrapper>
  );
};

export default TerminePanel;
