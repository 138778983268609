/*
 * Hello dear future contributor,
 * if you desire to create a panel, this file is for you. Here, you'll need to register your panel both with the
 * typings system and with the rendering system.
 */
import React from "react";
import FahrplanPanel from "./Fahrplan/FahrplanPanel";
import NextbikePanel from "./Nextbike/NextbikePanel";
import { PanelDefinition } from "../types/LayoutConfig";
import PanelWrapper from "../meta/PanelWrapper";
import BildPanel from "./Bild/BildPanel";
import MensaplanPanel from "./Mensaplan/MensaplanPanel";
import CalloutPanel from "./Callout/CalloutPanel";
import TerminePanel from "./Termine/TerminePanel";
import WetterPanel from "./Wetter/WetterPanel";
import MensaJetztPanel from "./MensaJetzt/MensaJetztPanel";
import GremiumPanel from "./Gremium/GremiumPanel";

/*
 * First, please claim a unique id for your panel here. Convention is that it is all lowercase, in snake-case to be
 * precise. So if you want to call your panel "My awesome Panel", please claim "my-awesome-panel". Add it by adding
 * `| "my-awesome-panel"` before the semicolon in the type below this comment.
 */

export type PanelTypes =
  | "fahrplan"
  | "nextbike"
  | "bild"
  | "mensaplan"
  | "callout"
  | "gremium"
  | "termine"
  | "wetter"
  | "mensa-jetzt";

/*
 * Next, add your renderer. You'll get the definition that was written in the layout config as a prop. If you'd like to
 * provide custom settings, you may add an object with these settings as the generic into the PanelDefinition.
 * It will then be available as `definition.config`.
 */
export const PanelRenderers: {
  [panelType: string]: React.FC<any & { definition: PanelDefinition<any> }>;
} = {
  fahrplan: FahrplanPanel,
  nextbike: NextbikePanel,
  bild: BildPanel,
  mensaplan: MensaplanPanel,
  callout: CalloutPanel,
  gremium: GremiumPanel,
  termine: TerminePanel,
  wetter: WetterPanel,
  "mensa-jetzt": MensaJetztPanel,
  placeholder: () => (
    <PanelWrapper
      className={"flex flex-col items-center justify-center text-zinc-400"}
    >
      Dieses Panel wird noch entwickelt
    </PanelWrapper>
  ),
};

/*
 * That should have been it. Now, have fun implementing your renderer!
 */
